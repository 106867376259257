export const DEFAULT_PAGE_SIZE = 50;
export const TABLE_BORDER_COLOR = "#f1f5f1";
export const NO_RECORD = "No Record Found.";
export const ADMIN_ROLE = 1;
export const EMPLOYER_ROLE = 2;
export const LAB_ROLE = 3;
export const EMPLOYEE_ROLE = 4;
export const STAFF_ROLE = 5;
export const PHYSICIAN_ROLE = 6;
export const PHLEBOTOMIST_ROLE = 7;
export const NURSE_ROLE = 8;

export const staffRoleOptions = [
  {
    title: "Staff",
    value: 5,
  },
  {
    title: "Physician",
    value: 6,
  },
  {
    title: "Phlebotomist",
    value: 7,
  },
  {
    title: "Nurse/Medtech",
    value: 8,
  },
];

export const TEST_ORDER_STATUS = [
  {
    value: 1,
    label: "New",
  },
  {
    value: 2,
    label: "Scheduled",
  },
  {
    value: 3,
    label: "Sample Collected",
  },
  {
    value: 4,
    label: "Results Delivered",
  },
  {
    value: 5,
    label: "Cancelled",
  },
  {
    value: 6,
    label: "Partially Delivered",
  },
  {
    value: 7,
    label: "Pending",
  },
];

export const ethnicityOptions = [
  {
    value: "1",
    label: "HISPANIC",
    regex: new RegExp("1", "i"),
  },
  {
    value: "2",
    label: "NON - HISPANIC",
    regex: new RegExp("2", "i"),
  },
];

export const paymentByOptions = [
  {
    value: "1",
    label: "Organization",
    regex: new RegExp("1", "i"),
  },
  {
    value: "2",
    label: "Insurance",
    regex: new RegExp("2", "i"),
  },
  {
    value: "3",
    label: "Self",
    regex: new RegExp("3", "i"),
  },
];

export const raceOptions = [
  {
    value: "1",
    label: "American Indian, Alaska Native",
  },
  {
    value: "2",
    label: "Asian",
  },
  {
    value: "3",
    label: "Black or African American",
  },
  {
    value: "4",
    label: "White",
  },
  {
    value: "5",
    label: "Other",
  },
];

export const insuranceNameOptions = [
  {
    value: "maxbupa",
    label: "Maxbupa",
  },
  {
    value: "bajaj allianz",
    label: "Bajaj Allianz",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const vaccineTypeOptions = [
  {
    value: "0",
    label: "Unvaccinated",
  },
  {
    value: "1",
    label: "Pfizer",
  },
  {
    value: "2",
    label: "Moderna",
  },
  {
    value: "3",
    label: "Johnson and Johnson",
  },
];
