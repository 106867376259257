import React, { Fragment } from "react";

const NotFound = () => {
  return (
    <Fragment>
    <div className="center-text-notFound">
      <h1 className="x-large text-primary">
        <i className="fa fa-exclamation-triangle"></i> Page Not Found
      </h1>
      <p className="large">Sorry, this page does not exist.</p>
      </div>
    </Fragment>
  );
};

export default NotFound;
