import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import HomePage from "views/Front";
import NotFoundPage from "views/NotFound";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import MemberLogin from "views/Auth/Member/Login";
import MemberForgotPassword from "views/Auth/Member/ForgotPassword";
import MemberForgotUsername from "views/Auth/Member/ForgotUsername";
import MemberResetPassword from "views/Auth/Member/ResetPassword";
import MemberSetPassword from "views/Auth/Member/SetPassword";
import DynamicImport from "./DynamicImport";
import PatientLoginAdmin from "views/Auth/Member/AdminLogin";
import ScheduleAppDemo from "views/ScheduleAppDemo";

const MemberPanel = (props) => (
  <DynamicImport load={() => import("views/Front/Member/Layout")}>
    {(Component) => (Component === null ? "" : <Component {...props} />)}
  </DynamicImport>
);

const Routes = () => {
  return (
    <span>
      <Switch>
        <PublicRoute
          exact
          path="/patient-admin-login"
          restricted={true}
          component={PatientLoginAdmin}
        />
        <PublicRoute
          exact
          path="/patient-login"
          restricted={true}
          component={MemberLogin}
        />
        <PublicRoute
          exact
          path="/forgotPassword"
          restricted={true}
          component={MemberForgotPassword}
        />
        <PublicRoute
          exact
          path="/forgotUsername"
          restricted={true}
          component={MemberForgotUsername}
        />
        <PublicRoute
          exact
          path="/set-password"
          restricted={true}
          component={MemberSetPassword}
        />
        <PublicRoute
          exact
          path="/resetPassword/:token"
          restricted={true}
          component={MemberResetPassword}
        />

        <PublicRoute
          exact
          path="/scheduler-app-launcher"
          restricted={false}
          component={ScheduleAppDemo}
        />
        <PrivateRoute exact path="/patient">
          <Redirect to="/patient/profile" />
        </PrivateRoute>
        <PrivateRoute path="/patient*" component={MemberPanel} />
        <Route exact path="/*" name="Home" component={HomePage} />
        <Route component={NotFoundPage} />
      </Switch>
    </span>
  );
};

export default Routes;
