import {
  FRONTMEMBER_TESTORDER_LIST_LOADING,
  FRONTMEMBER_TESTORDER_RESET,
  FRONTMEMBER_TESTORDER_LOAD_PAGE,
  FRONTMEMBER_TESTORDER_ERROR,
  FRONTMEMBER_TESTORDER_LOADING_ON_SUBMIT,
  FRONTMEMBER_TESTORDER_GET_BY_ID,
  FRONTMEMBER_TESTORDER_UPDATED,
  FRONTMEMBER_TESTORDER_LIST_UPDATED,
  FRONTMEMBER_TESTORDER_SEARCH_PARAMATERS_UPDATE,
} from "actions/types";
import * as Constants from "constants/index";
//
const initialState = {
  testOrderList: {
    page: 1,
    data: [],
    count: 0
  },
  currentTestOrder: null,
  loadingTestOrderList: true,
  loadingTestOrder: true,
  // loadingUploadTestOrder: false,
  loadingListData: true,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
  performDelete: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTMEMBER_TESTORDER_RESET:
      return {
        ...initialState,
        loadingTestOrder: state.loadingTestOrder,
        loadingTestOrderList: state.loadingTestOrderList,
        loadingListData: state.loadingListData,
      }
    case FRONTMEMBER_TESTORDER_LOAD_PAGE:
      return {
        ...state,
        loadingTestOrder: false,
        loadingListData: false,
        // loadingUploadTestOrder: false
      };
    case FRONTMEMBER_TESTORDER_UPDATED:
      return {
        ...state,
        currentTestOrder: null,
        sortingParams: initialState.sortingParams,
        loadingTestOrder: false,
      };
    case FRONTMEMBER_TESTORDER_ERROR:
      return {
        ...state,
        error: payload,
        loadingTestOrder: false,
        loadingTestOrderList: false,
        loadingListData: false,
        performDelete: false,
      };
    case FRONTMEMBER_TESTORDER_GET_BY_ID:
      return {
        ...state,
        currentTestOrder: payload,
        loadingTestOrder: false,
      };
    case FRONTMEMBER_TESTORDER_LIST_UPDATED:
      return {
        ...state,
        testOrderList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingTestOrderList: false,
        loadingListData: false,
        performDelete: false,
      };
    case FRONTMEMBER_TESTORDER_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload }
      };
    case FRONTMEMBER_TESTORDER_LIST_LOADING:
      return {
        ...state,
        loadingListData: true
      };
    case FRONTMEMBER_TESTORDER_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingTestOrder: true,
        // loadingUploadTestOrder: true
      };
    default:
      return state;
  }
}
