import axios from "axios";

// Verify Email
export const verifyAccessToken = (token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };
  try {
    const res = await axios.get(
      `/sso-apis/test-orders/verify-access-token`,
      config
    );
    if (res.data.status === true) {
      return res.data.response;
    } else {
      console.log(res.data.errors);
    }
  } catch (err) {
    console.log(err);
  }
};
