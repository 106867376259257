import React from "react";
import { connect } from "react-redux";

import { verifyAccessToken } from "actions/verifyOrderToken";

const ScheduleAppDemo = ({ verifyAccessToken, match }) => {
  const [verifying, setVerifying] = React.useState(false);
  const [closingText, setClosingText] = React.useState("");
  const [data1, setData1] = React.useState(null);

  React.useEffect(() => {
    if (!window.opener) {
      window.close();
    }

    setVerifying(true);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let accessToken = params.get("x-access-token");

    console.log("access token", accessToken);

    if (!accessToken) return;

    verifyAccessToken(accessToken).then((response) => {
      setVerifying(false);
      if (response) {
        setData1(response);
      }
      console.log({ response });
      // initClosingTabTimer();
    });
  }, []);

  const initClosingTabTimer = () => {
    console.log("window.opener", window.opener);

    setClosingText("Closing window automatically in 10 seconds...");

    setTimeout(() => {
      onClickWindowCloseHandler();
    }, 10000);
  };

  const onClickWindowCloseHandler = () => {
    window.close();
  };

  return (
    <div
      style={{
        margin: "50px auto",
        width: "400px",
        minHeight: "300px",
        border: "1px solid #e6e6e6",
        borderRadius: "10px",
        padding: "10px",
        textAlign: "center",
      }}
    >
      {verifying ? <p>Verifying token...</p> : <p>Token Verified</p>}
      <br></br>

      {data1 ? (
        <div>
          <p>
            iLab Order #: <b>{data1.order.orderNumber}</b>
          </p>
          <p>
            Company: <b>{data1.company.companyName}</b>
          </p>
          <p>
            Patent: <b>{data1.patient.patientName}</b>
          </p>
          {data1.patient.address ? (
            <p>
              Patent Address:{" "}
              {data1.patient.address
                ? `${data1.patient.address.addressLine1} ${
                    data1.patient.address.addressLine2
                  } ${
                    data1.patient.address.city &&
                    data1.patient.address.city.cityName
                      ? data1.patient.address.city.cityName +
                        " " +
                        data1.patient.address.city.state
                      : ""
                  }`
                : ""}
            </p>
          ) : null}

          <br></br>
          <br></br>

          <div>
            <code>data2 to be sent to iLab:</code> <br></br>
            orderId: {data1.order.orderId},<br></br>
            patientId: {data1.patient.patientId},<br></br>
            providerId: "physician-id", <br></br>
            appointmentDate: "2022-08-10", <br></br>
            appointmentTime: "12:30:00"
          </div>
        </div>
      ) : null}

      <br></br>
      {closingText ? <p>{closingText}</p> : null}

      <button onClick={onClickWindowCloseHandler}>Close window</button>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { verifyAccessToken })(ScheduleAppDemo);
