import React, { Fragment } from "react";
import { connect } from "react-redux";
import Header from "./Layout/Header";
import { Route, Switch } from "react-router-dom";
import FrontRoutes from "views/Routing/FrontRoutes";

const Home = () => {
  return (
      <Fragment>
        <Header />
            <Switch>
              {FrontRoutes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    component={route.component}
                  />
                ) : null;
              })}
            </Switch>
      </Fragment>
  );
};

export default connect()(Home);
