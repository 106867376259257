import { UPDATE_EMPLOYMENT_TESTS_LIST } from "actions/types";

const initialState = {
  drugTestsGlobal: [],
  labTestsGlobal: [],
  medicalTestsGlobal: [],
  drugIndividualTests: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_EMPLOYMENT_TESTS_LIST:
      return {
        ...state,
        drugTestsGlobal: payload.drugTestsGlobal,
        labTestsGlobal: payload.labTestsGlobal,
        medicalTestsGlobal: payload.medicalTestsGlobal,
        drugIndividualTests: payload.drugIndividualTests,
      };
    default:
      return state;
  }
}
