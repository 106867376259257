import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, loading, user },
  ...rest
}) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated && !loading) {
          return <Redirect to="/" />;
        } else {
          if (user !== null) {
            if (props.match.path.match(/patient/)) return <Component {...props} />;
            else return <Redirect to="/patient/profile" />;
          }
          else return <Component {...props} />;
        }
      }}
    />
  );
};
PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
