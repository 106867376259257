import axios from "axios";
import { setAlert, removeAlert } from "./alert";
import { setErrorsList } from "./errors";
import * as Constants from "constants/index";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  RESET_LINK_SUCCESS,
  RESET_LINK_FAIL,
  USER_LOADED,
  USER_PROFILE_LOADED,
  AUTH_TOKEN_REFRESH,
  AUTH_ERROR,
  LOGOUT,
  LOGIN_OTP_SENT_SUCCESS,
  ENABLE_RESEND_OTP_OPTION,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  URL_CHANGE,
  LOADING_ON_LOGIN_SUBMIT,
  LOADING_ON_PASSWORD_RESET,
  REMOVE_ERRORS,
  REMOVE_ALERT,
  LOAD_AUTH_PAGE,
  PENDING_ACTION_LOADED,
  FRONTMEMBER_ERROR,
} from "./types";
import setAuthToken from "../utils/setAuthToken";

// const interval = 3000000;
// let authTimer = null;
export const loadAuthPage = (history,prePath="") => async dispatch => {

  dispatch({ type: REMOVE_ERRORS });
  dispatch({
    type: URL_CHANGE,
    payload: history.location.pathname
  });
};
//Load User
export const loadUser = (token="") => async dispatch => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  if(token){
    setAuthToken(token);
  }
  // console.log("token------>",token);
  try {
    const res = await axios.get(`/api/auth/patient`);
    if (res.data.status === true) {
      dispatch({
        type: USER_LOADED,
        payload: res.data.response
      });

      dispatch({
        type: USER_PROFILE_LOADED,
        payload: res.data.response
      });
      // authTimer = setInterval(function(){
      //   dispatch(refreshAuthToken());
      // },interval);
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
      }
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: AUTH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Refresh Auth Token
export const refreshAuthToken = () => async dispatch => {
  try {
    const res = await axios.get(`/api/auth/refreshToken`);

    if (res.data.status === true) {
      dispatch({
        type: AUTH_TOKEN_REFRESH,
        payload: res.data.response
      });
      if (res.data.response.token) {
        setAuthToken(res.data.response.token);
      }
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
      }
    }
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Register User
export const register = (
  { first_name, last_name, email, password },
  history
) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ first_name, last_name, email, password });

  try {
    dispatch(removeAlert());
    dispatch({ type: REMOVE_ERRORS });
    const res = await axios.post(`/api/register`, body, config);
    if (res.data.status === true) {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data.response
      });
      const loginData = JSON.stringify({ email, password });
      dispatch(login(loginData));
      //history.push("/")
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach(error => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
  } catch (err) {
    dispatch({
      type: REGISTER_FAIL,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Logout / Clear Profile
export const logout = () => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    // clearInterval(authTimer);
    dispatch(removeAlert());
    dispatch({ type: REMOVE_ERRORS });
    const res = await axios.put(`/api/auth/logout`, {}, config);
    if (res.data.status === true) {
      setAuthToken();
      dispatch({ type: LOGOUT });
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach(error => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(removeAlert());
        setAuthToken();
        dispatch({ type: REMOVE_ERRORS });
        dispatch({ type: LOGOUT });
      }
      else {
        dispatch({
          type: AUTH_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status }
        });
        dispatch(setAlert(err.response.data.message || err.response.statusText, "danger"));
      }
    }
  }
};

//Logout / Clear Profile
export const labLogout = history => dispatch => {
  dispatch(removeAlert());
  setAuthToken();
  dispatch({ type: REMOVE_ERRORS });
  dispatch({ type: LOGOUT });
  // history.push("/lab/login");
};
export const EmployerLogout = (history) => (dispatch) => {
  dispatch(removeAlert());
  setAuthToken();
  dispatch({ type: REMOVE_ERRORS });
  dispatch({ type: LOGOUT });
  // history.push("/lab/login");
};
export const MemberLogout = (history) => (dispatch) => {
  dispatch(removeAlert());
  setAuthToken();
  dispatch({ type: REMOVE_ERRORS });
  dispatch({ type: LOGOUT });
  // history.push("/lab/login");
};

//Login User
export const login = (formData, history) => async dispatch => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnLoginSubmit());

  const config = { headers: { "Content-Type": "application/json" } };
  // const body = JSON.stringify({ email, password });
  try {
    dispatch(removeAlert());
    dispatch({ type: REMOVE_ERRORS });
    // console.log("login formData", formData);
    const res = await axios.post(`/api/auth`, formData, config);
    console.log("------------>>>>.",res);
    if (res.data.status === true && res.data.response.authId && res.data.response.askForTwoFactor === true) {
      dispatch(setAlert(res.data.message, "success"));
      dispatch({
        type: LOGIN_OTP_SENT_SUCCESS
      });
    } else if (res.data.status === true && res.data.response.user.role) {
      if (
        formData.userType &&
        (res.data.response.user.role !== formData.userType ||
          res.data.response.user.role !== Constants.LAB_ROLE ||
          res.data.response.user.role !== Constants.EMPLOYEE_ROLE )
        ) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data.response,
        });

        dispatch(loadUser());
        //Remember me
        if (
          res.data.response.user.role &&
          (res.data.response.user.role === Constants.EMPLOYER_ROLE ||
            res.data.response.user.role === Constants.LAB_ROLE ||
            res.data.response.user.role === Constants.EMPLOYEE_ROLE)
        ) {
          if (formData.remember_me) {
            localStorage.setItem("rmcheck", formData.remember_me);
            if (formData.loginType === "email") {
              localStorage.setItem(
                "rmval",
                btoa(formData.loginType) +
                  "-" +
                  btoa(formData.email) +
                  "-" +
                  btoa(formData.password)
              );
            } else {
              localStorage.setItem(
                "rmval",
                btoa(formData.loginType) +
                  "-" +
                  btoa(formData.ccode) +
                  "-" +
                  btoa(formData.phone) +
                  "-" +
                  btoa(formData.password)
              );
            }
          } else {
            localStorage.removeItem("rmcheck");
            localStorage.removeItem("rmval");
          }
          console.log(
            "res.data.response.user.setPassword",
            res.data.response.user.setPassword
          );
          if (res.data.response.user.setPassword) {
            history.push("/set-password");
          } else {
            switch (res.data.response.user.role) {
              case Constants.LAB_ROLE:
                history.push("/lab/test-order");
                break;
              case Constants.EMPLOYER_ROLE:
                history.push("/organization");
                break;
              case Constants.EMPLOYEE_ROLE:
                console.log("1")
                history.push("/patient/test-order");
                break;
            }
          }
        } else {
          dispatch({
            type: AUTH_ERROR,
          });
        }
      }
    } else {
      // dispatch(setAlert(res.data.message, "danger"));
      dispatch({
        type: AUTH_ERROR,
      });

      if(formData.otp){
        dispatch({
          type: ENABLE_RESEND_OTP_OPTION,
        });
      }

      const errors = res.data.errors;
      if (errors) {
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
  } catch (err) {
    if (err.response) {
      // console.log(err.response);
      dispatch({
        type: LOGIN_FAIL,
        payload: { msg: err.response.data.message || err.response.statusText, status: err.response.status }
      });
      dispatch(setAlert(err.response.data.message || err.response.statusText, "danger"));
    }
  }
};

//Login User
export const loginMember = (formData, history) => async dispatch => {
  dispatch({ type: REMOVE_ERRORS });
  dispatch(loadingOnLoginSubmit());

  const config = { headers: { "Content-Type": "application/json" } };
  // const body = JSON.stringify({ email, password });
  try {
    dispatch(removeAlert());
    dispatch({ type: REMOVE_ERRORS });
    // console.log("login formData", formData);
    const res = await axios.post(`/api/auth/patient/login`, formData, config);
    if (res.data.status === true && res.data.response.authId && res.data.response.askForTwoFactor === true) {
      dispatch(setAlert(res.data.message, "success"));
      dispatch({
        type: LOGIN_OTP_SENT_SUCCESS
      });
    } else if (res.data.status === true && res.data.response.user.role) {
      if (
        formData.userType &&
        (res.data.response.user.role !== formData.userType ||
          res.data.response.user.role !== Constants.LAB_ROLE ||
          res.data.response.user.role !== Constants.EMPLOYEE_ROLE )
        ) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data.response,
        });

        dispatch(loadUser());
        //Remember me
        if (
          res.data.response.user.role &&
          (res.data.response.user.role === Constants.EMPLOYER_ROLE ||
            res.data.response.user.role === Constants.LAB_ROLE ||
            res.data.response.user.role === Constants.EMPLOYEE_ROLE)
        ) {
          if (formData.remember_me) {
            localStorage.setItem("rmcheck", formData.remember_me);
            if (formData.loginType === "email") {
              localStorage.setItem(
                "rmval",
                btoa(formData.loginType) +
                  "-" +
                  btoa(formData.email) +
                  "-" +
                  btoa(formData.password)
              );
            } else {
              localStorage.setItem(
                "rmval",
                btoa(formData.loginType) +
                  "-" +
                  btoa(formData.ccode) +
                  "-" +
                  btoa(formData.phone) +
                  "-" +
                  btoa(formData.password)
              );
            }
          } else {
            localStorage.removeItem("rmcheck");
            localStorage.removeItem("rmval");
          }
          if (res.data.response.user.setPassword) {
            history.push("/set-password");
          } else {
            history.push("/patient");
          }
        } else {
          dispatch({
            type: AUTH_ERROR,
          });
        }
      }
    } else {
      // dispatch(setAlert(res.data.message, "danger"));
      dispatch({
        type: AUTH_ERROR,
      });

      if(formData.otp){
        dispatch({
          type: ENABLE_RESEND_OTP_OPTION,
        });
      }

      const errors = res.data.errors;
      if (errors) {
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
  } catch (err) {
    if (err.response) {
      // console.log(err.response);
      dispatch({
        type: LOGIN_FAIL,
        payload: { msg: err.response.data.message || err.response.statusText, status: err.response.status }
      });
      dispatch(setAlert(err.response.data.message || err.response.statusText, "danger"));
    }
  }
};

// Generate Reset Link
export const resetLink = (form) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    dispatch(removeAlert());
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnPasswordReset());
    const res = await axios.post(`/api/forgotPasswordPatient`, form, config);
    if (res.data.status === true) {
      dispatch({
        type: RESET_LINK_SUCCESS,
        payload: res.data.response
      });
      dispatch(
        setAlert(res.data.message, "success")
      );
    } else {
      const errors = res.data.errors;
      dispatch({
        type: RESET_LINK_FAIL,
        payload: { msg: res.data.message }
      });
      if (errors) {
        // dispatch(setAlert(res.data.message, "danger"));
        errors.forEach(error => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
  } catch (err) {
    if (err.response) {
      dispatch({
        type: RESET_LINK_FAIL,
        payload: { msg: err.response.data.message || err.response.statusText, status: err.response.status }
      });
    }
  }
};

// Generate Reset Link
export const forgotUsernameLink = (formData) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  try {
    dispatch(removeAlert());
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnPasswordReset());
    const res = await axios.post(`/api/forgotUsernamePatient`, formData, config);
    if (res.data.status === true) {
      dispatch({
        type: RESET_LINK_SUCCESS,
        payload: res.data.response
      });
      dispatch(
        setAlert(res.data.message, "success")
      );
    } else {
      const errors = res.data.errors;
      dispatch({
        type: RESET_LINK_FAIL,
        payload: { msg: res.data.message }
      });
      if (errors) {
        // dispatch(setAlert(res.data.message, "danger"));
        errors.forEach(error => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
  } catch (err) {
    if (err.response) {
      dispatch({
        type: RESET_LINK_FAIL,
        payload: { msg: err.response.data.message || err.response.statusText, status: err.response.status }
      });
    }
  }
};

// Verify Reset Password Token Validity
export const getResetTokenStatus = (token) => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axios.get(
      `/api/forgotPasswordPatient/${token}`,
      config
    );
    if (res.data.status === true) {
      await dispatch({
        type: RESET_LINK_SUCCESS,
        payload: res.data.response
      });
    } else {
      dispatch(setAlert(res.data.message, "danger"));
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      dispatch({
        type: RESET_LINK_FAIL,
        payload: { msg: err.response.data.message || err.response.statusText, status: err.response.status }
      });
    }
  }
};

// Token invalid
export const tokenInvalid = (history, type) => async dispatch => {
  history.push("/forgotPasswordPatient");
};

//Dispatch Confirm password error
export const setPasswordError = (msg, param) => async dispatch => {
  dispatch(setErrorsList(msg, param));
};

// Reset Password
export const resetPassword = (password, token, history) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    dispatch(removeAlert());
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnPasswordReset());
    const res = await axios.post(`/api/forgotPasswordPatient/${token}`, { password }, config);
    if (res.data.status === true) {
      dispatch({
        type: RESET_LINK_SUCCESS,
        payload: res.data.response
      });
      dispatch(setAlert(res.data.message, "success"));
      history.push("/patient-login");

    } else {
      // dispatch(tokenInvalid());
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
        dispatch({
          type: RESET_LINK_FAIL
        });
        errors.forEach(error => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      dispatch({
        type: RESET_LINK_FAIL,
        payload: { msg: err.response.data.message || err.response.statusText, status: err.response.status }
      });
    }else if(err.errors){
      dispatch({
        type: RESET_LINK_FAIL,
        payload: { msg: err.message , status: err.response.status }
      });
    }
  }
};
export const setPassword = (password, id,token,history) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    console.log("test");
    dispatch(removeAlert());
    dispatch({ type: REMOVE_ERRORS });
    dispatch(loadingOnPasswordReset());
    const res = await axios.post(
      `/api/employer/set-password`,
      { password,id },
      config
    );
    if (res.data.status === true) {
      loadUser();
      // dispatch(setAlert(res.data.message, "Password set successful"));
      history.push("/organization");
    } else {
      // dispatch(tokenInvalid());
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));
        dispatch({
          type: RESET_LINK_FAIL,
        });
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response) {
      dispatch({
        type: RESET_LINK_FAIL,
        payload: {
          msg: err.response.data.message || err.response.statusText,
          status: err.response.status,
        },
      });
    } else if (err.errors) {
      dispatch({
        type: RESET_LINK_FAIL,
        payload: { msg: err.message, status: err.response.status },
      });
    }
  }
};

// Redirect to forgot password screen
export const forgotPassword = (history, type) => async dispatch => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("/forgotPassword");
};

// Redirect to forgot username screen
export const forgotUsername = (history, type) => async dispatch => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("/forgotUsername");
};

// Redirect to Login screen
export const loginRedirect = (history, type) => async dispatch => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("/patient-login");
};

//setErrors
export const setErrors = errors => async dispatch => {
  if (errors) {
    dispatch({ type: FRONTMEMBER_ERROR });
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach(error => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

// Redirect to register screen
export const registerRedirect = history => async dispatch => {
  dispatch({ type: REMOVE_ALERT });
  dispatch({ type: REMOVE_ERRORS });
  history.push("/register");
};


// Dispatch Loading
export const loadingOnLoginSubmit = () => async dispatch => {
  await dispatch({ type: LOADING_ON_LOGIN_SUBMIT });
};

// Dispatch Password Reset Loading
export const loadingOnPasswordReset = () => async dispatch => {
  await dispatch({ type: LOADING_ON_PASSWORD_RESET });
};


// Load Page/Show Page
export const loadPage = () => async dispatch => {

  await dispatch({ type: LOAD_AUTH_PAGE });
}

// // page not found
// export const notFound = history => async dispatch => {
//   history.push("/page-not-found");
// };

//Load Action pending count
export const loadPendingAction = () => async dispatch => {
  dispatch(loadingOnLoginSubmit());
  const res = await axios.get(`/api/auth/getAdminPendingAction`);
  if (res.data.status === true) {
    dispatch({
      type: PENDING_ACTION_LOADED,
      payload: res.data.response.action_pending
    });
    dispatch(loadPage());
  }
}

//function to update sidebar pending action count
export const updateSidebar = (data) => async dispatch => {
  try {
    if (data && data.action_pending) {
      dispatch({
        type: PENDING_ACTION_LOADED,
        payload: data.action_pending
      });
    }
  }
  catch (err) {
    console.log("error", err);
  }
}
