import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  resetLink,
  loadPage,
  loadingOnLoginSubmit,
  loginRedirect,
} from "actions/auth";
import { sfold, checkScripts } from "utils/sFold";
import Errors from "views/Notifications/Errors";
import Alert from "views/Notifications/Alert";
import Spinner from "views/Spinner";
import Img from "react-image";
import LogoSvg from "assets/iLabDx-Logo.png";
import { Helmet } from "react-helmet";
import extractNumber from "utils/extractNumber";
import "react-phone-number-input/style.css";
import PhoneInput, {
  getCountryCallingCode,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { uniqueString } from "utils/nodeHelper";

import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const ResetLink = ({
  resetLink,
  loadPage,
  loading,
  loadingOnLoginSubmit,
  loginRedirect,
  errorList,
  history,
}) => {
  //################## For css ##################
  const [loadingCSS, setLoadingCSS] = useState(true);
  const [onlyOnce, setOnce] = useState(true);
  let stylesNeedToBeLoaded = 0;
  let stylesLoaded = 0;

  const InjectCSS = async () => {
    try {
      const cssArray = [
        "/assets/lab/plugins/bootstrap/css/bootstrap.min.css",
        "/assets/lab/css/style.css",
        "/assets/lab/css/custom.css",
        "/assets/lab/color-skins/color10.css",
      ];
      stylesNeedToBeLoaded = cssArray.length;
      if (!checkScripts("lab_panel_css"))
        cssArray.forEach((url) => {
          addStyleScript(url, "css", "lab_panel_css");
        });
      else setLoadingCSS(false);
    } catch (err) {
      console.log(err);
    }
  };

  const styleLoaded = (id) => {
    stylesLoaded++;
    if (stylesNeedToBeLoaded === stylesLoaded) {
      setLoadingCSS(false);
    }
  };

  const addStyleScript = (url, type, package_name) => {
    let tag,
      tag_id = sfold(package_name);
    switch (type) {
      case "css":
        tag = document.createElement("link");
        tag.rel = "stylesheet";
        tag.href = url;
        tag.async = true;
        tag.dataset.id = tag_id;
        tag.onload = () => styleLoaded(tag_id);
        break;
      default:
        return;
    }
    document.head.appendChild(tag);
  };

  useMemo(() => {
    if (onlyOnce) {
      InjectCSS();
      loadPage();
      setOnce(false);
    }
  }, [loadPage]);

  //############### Define form state ###############################
  const [formData, setFormData] = useState({
    usernameOrEmail: "",
    ccode: "+1",
    iute164_phone: "",
    phone: "",
    iso: "US",
  });

  const { usernameOrEmail, ccode, iute164_phone, phone, iso } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onPhoneChange = (number) => {
    const phone = extractNumber(number, ccode);
    console.log(phone, ccode);
    setFormData((form) => ({
      ...form,
      phone: phone,
      iute164_phone: number || ccode,
    }));
  };
  const onCountryChange = (code) => {
    setFormData((form) => ({
      ...form,
      iso: code || "US",
      ccode: "+" + getCountryCallingCode(code || "US"),
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    loadingOnLoginSubmit();
    if (formData.phone) {
      formData.usernameOrEmail = "";
    } else {
      formData.iute164_phone = "";
    }
    let submitData = {};
    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }
    resetLink(submitData).then((res) => {
      loadPage();
    });
  };

  return loadingCSS || loading ? (
    <Spinner />
  ) : (
    <>
      <Helmet>
        <title>Forgot password - {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <div className="page page-h">
        <div className="page-content z-index-10">
          <Container>
            <Row>
              <Col xl="4" md="12" className="d-block mx-auto">
                <div className="text-center w-100 d-block mb-4">
                  <Img alt="" src={LogoSvg} className="w-70" />
                </div>

                <Card className="mb-0">
                  <CardHeader>
                    <h3 className="card-title">Forgot Password</h3>
                  </CardHeader>
                  <CardBody>
                    <Alert />
                    <Form onSubmit={(e) => onSubmit(e)}>
                      <FormGroup>
                        <Label className="form-label text-dark">
                          Username/Email
                        </Label>
                        <Input
                          type="text"
                          placeholder=""
                          autoComplete={uniqueString()}
                          name="usernameOrEmail"
                          maxLength="100"
                          value={usernameOrEmail}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.usernameOrEmail ? true : false}
                        />
                        <Errors
                          current_key="usernameOrEmail"
                          key="usernameOrEmail"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="form-label or-css" htmlFor="phone">
                          ---------- OR ----------
                        </Label>
                      </FormGroup>
                      <FormGroup>
                        <Label className="form-label" htmlFor="phone">
                          Phone
                        </Label>
                        <PhoneInput
                          className="hk_custom_sag"
                          autoComplete={uniqueString()}
                          international={true}
                          defaultCountry="US"
                          countryOptionsOrder={["US", "|", "..."]}
                          value={iute164_phone}
                          name="phone"
                          addInternationalOption={false}
                          onChange={onPhoneChange}
                          onCountryChange={onCountryChange}
                        />
                        <Errors
                          current_key="iute164_phone"
                          key="iute164_phone"
                        />
                      </FormGroup>
                      <div className="form-footer mt-2">
                        <button className="btn btn-primary btn-block">
                          Send Password Reset Link
                        </button>
                      </div>
                      <div className="text-center  mt-3 text-dark">
                        Have an account?{" "}
                        <b>
                          <Link
                            to="#"
                            onClick={(e) => loginRedirect(history, "member")}
                          >
                            SignIn
                          </Link>{" "}
                        </b>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

ResetLink.propTypes = {
  resetLink: PropTypes.func.isRequired,
  loadingOnLoginSubmit: PropTypes.func.isRequired,
  loadPage: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  resetLink,
  loadPage,
  loadingOnLoginSubmit,
  loginRedirect,
})(ResetLink);
