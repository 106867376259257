import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "views/Spinner";

const PublicRoute = ({
  component: Component,
  auth: { isAuthenticated, loading, user },
  authId,
  restricted,
  ...rest
}) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated && !loading && !restricted) {
          console.log("transferred volume");
          return <Component {...props} />;
        } else {
          if (isAuthenticated !== null) {
            if (user !== null) {
              if (user && user.setPassword) {
                return <Component {...props} />;
              }
              return <Redirect to="/patient/profile" />;
            }

            return <Component {...props} />;
          }

          return <Spinner />;
        }
      }}
    />
  );
};

PublicRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  authId: state.auth.authId,
});

export default connect(mapStateToProps)(PublicRoute);
