import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import Routes from "./views/Routing/Routes";
import "App.scss";

if (localStorage.token) {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let token = params.get("token");
  if (token) {
    setAuthToken();
    localStorage.setItem("token", token);
  } else
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route component={Routes} />
        </Switch>
      </Router>
    </Provider>
  );
};
export default App;
