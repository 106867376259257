import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setAlert } from "actions/alert";
import {
  resetPassword,
  setPasswordError,
  getResetTokenStatus,
  tokenInvalid,
  loadPage,
  loadingOnLoginSubmit,
  loginRedirect,
} from "actions/auth";
import { sfold, checkScripts } from "utils/sFold";
import Errors from "views/Notifications/Errors";
import Alert from "views/Notifications/Alert";
import PropTypes from "prop-types";
import Spinner from "views/Spinner";
import Img from "react-image";
import LogoSvg from "assets/iLabDx-Logo.png";
import { Helmet } from "react-helmet";

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const ResetPassword = ({
  setAlert,
  setPasswordError,
  loading,
  resetPassword,
  getResetTokenStatus,
  tokenInvalid,
  loadPage,
  history,
  errorList,
  match,
  loginRedirect,
}) => {
  //################## For css ##################
  const [loadingCSS, setLoadingCSS] = useState(true);
  const [onlyOnce, setOnce] = useState(true);
  let stylesNeedToBeLoaded = 0;
  let stylesLoaded = 0;
  const [type, setPassType] = useState("password");

  const InjectCSS = async () => {
    try {
      const cssArray = [
        "/assets/lab/plugins/bootstrap/css/bootstrap.min.css",
        "/assets/lab/css/style.css",
        "/assets/lab/css/custom.css",
        "/assets/lab/color-skins/color10.css",
      ];
      stylesNeedToBeLoaded = cssArray.length;
      if (!checkScripts("lab_panel_css"))
        cssArray.forEach((url) => {
          addStyleScript(url, "css", "lab_panel_css");
        });
      else setLoadingCSS(false);
    } catch (err) {
      console.log(err);
    }
  };

  const styleLoaded = (id) => {
    stylesLoaded++;
    if (stylesNeedToBeLoaded === stylesLoaded) {
      setLoadingCSS(false);
    }
  };

  const addStyleScript = (url, type, package_name) => {
    let tag,
      tag_id = sfold(package_name);
    switch (type) {
      case "css":
        tag = document.createElement("link");
        tag.rel = "stylesheet";
        tag.href = url;
        tag.async = true;
        tag.dataset.id = tag_id;
        tag.onload = () => styleLoaded(tag_id);
        break;
    }
    document.head.appendChild(tag);
  };

  //############ Set Form Data ###################################
  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
  });
  const [successful, setSuccessful] = useState(false);
  const { password, confirm_password } = formData;

  useMemo(() => {
    getResetTokenStatus(match.params.token).then((data) => {
      !data && tokenInvalid(history, "member");
    });
    if (onlyOnce) {
      InjectCSS();
      loadPage();
      setOnce(false);
    }
  }, [
    getResetTokenStatus,
    match.params.token,
    history,
    tokenInvalid,
    loadPage,
  ]);

  /* useMemo(() => {
    console.log(match.params.id, match.params.token);
    getEmailById(match.params.id, match.params.token).then(data => {
      data && data.status === true
        ? setFormData({
          email: data.response.email
        })
        : tokenInvalid(history, "lab");
    });
    if (onlyOnce) {
      InjectCSS();
      loadPage();
      setOnce(false);
    }
  }, [getEmailById, match.params, history, tokenInvalid, loadPage]); */
  const showHide = () => {
    setPassType(type === "input" ? "password" : "input");
  };
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirm_password) {
      setPasswordError("Passwords do not match", "confirm_password");
      setAlert(
        "Errors! Please correct the following errors and submit again.",
        "danger"
      );
    } else {
      resetPassword(password, match.params.token, history).then((res) => {
        if (res && res.status === true) {
          setSuccessful(true);
        }
      });
    }
  };

  return loadingCSS ? (
    <Spinner />
  ) : (
    <>
      <Helmet>
        <title>Reset password - {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <div className="page page-h">
        <div className="page-content z-index-10">
          <Container>
            <Row>
              <Col xl="4" md="12" className="d-block mx-auto">
                <div className="text-center w-100 d-block mb-4">
                  <Img alt="" src={LogoSvg} className="w-70" />
                </div>

                <Card className="mb-0">
                  <CardHeader>
                    {" "}
                    <h3 className="card-title">Reset Password</h3>{" "}
                  </CardHeader>
                  <CardBody>
                    <Alert />
                    <Form onSubmit={(e) => onSubmit(e)}>
                      <FormGroup className="password-box">
                        <Label className="form-label text-dark">
                          Password*
                        </Label>
                        <Input
                          type={type}
                          placeholder="Password"
                          autoComplete="password"
                          name="password"
                          minLength="8"
                          value={password || ""}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.password ? true : false}
                        />

                        <i
                          className={
                            (type === "input"
                              ? "eye-symbol fa fa-eye"
                              : "eye-symbol fa fa-eye-slash") + " eye-login"
                          }
                          onClick={showHide}
                        ></i>

                        <Errors current_key="password" key="password" />
                      </FormGroup>
                      <FormGroup>
                        <Label className="form-label text-dark">
                          Confirm Password*
                        </Label>
                        <Input
                          type={type}
                          placeholder="Confirm password"
                          autoComplete="confirm_password"
                          name="confirm_password"
                          minLength="8"
                          value={confirm_password || ""}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.confirm_password ? true : false}
                        />

                        <Errors
                          current_key="confirm_password"
                          key="confirm_password"
                        />
                      </FormGroup>
                      {loading ? (
                        <Spinner />
                      ) : (
                        <>
                          <div className="form-footer mt-2">
                            <button className="btn btn-primary btn-block">
                              Reset Password{" "}
                            </button>
                          </div>
                          <div className="text-center  mt-3 text-dark">
                            Have an account?{" "}
                            <b>
                              {" "}
                              <Link
                                to="#!"
                                onClick={(e) =>
                                  loginRedirect(history, "employer")
                                }
                              >
                                {" "}
                                SignIn
                              </Link>{" "}
                            </b>
                          </div>
                        </>
                      )}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

ResetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  setPasswordError: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  loadingOnLoginSubmit: PropTypes.func.isRequired,
  tokenInvalid: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.auth.loadingPasswordReset,
});

export default connect(mapStateToProps, {
  setAlert,
  setPasswordError,
  resetPassword,
  getResetTokenStatus,
  tokenInvalid,
  loadPage,
  loadingOnLoginSubmit,
  loginRedirect,
})(ResetPassword);
