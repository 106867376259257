import { combineReducers } from "redux";

import alert from "./alert";
import auth from "./auth";
import errors from "./errors";
import MemberProfile from "./front/Member/MemberProfile";
import TestOrderMember from "./front/Member/TestOrder";
import testConfig from "./testConfigReducer";

const appReducer = combineReducers({
  alert,
  auth,
  errors,
  MemberProfile,
  TestOrderMember,
  testConfig,
});

export const rootReducer = (state, action) => {
  return appReducer(state, action);
};
