// parse extension with filename
export const parseExtension = (filename) => {
  let extensionsList = filename.split(".");
  let extLen = extensionsList.length;
  return extensionsList[extLen - 1];
};

export const uniqueString = (length = 16) => {
  let randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
};

export const displayOrgName = (orgInfo) => {
  if (!orgInfo.companyBuildingName) {
    return orgInfo.companyName;
  } else {
    return `${orgInfo.companyName}`; //  - ${orgInfo.companyBuildingName}
  }
};
