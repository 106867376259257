import React, { useState, useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
// import PropTypes from "prop-types";
// import Alert from "views/Notifications/Alert";
import Spinner from "views/Spinner";
import { loadUser } from "actions/auth";
// login
// import setAuthToken from "utils/setAuthToken";

const PatientAdminLogin = ({ history, loadUser }) => {
  // const dispatch = useDispatch();

  //############### For adding Css ###########################

  useMemo(async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");

    await localStorage.setItem("token", token);

    await loadUser(token);
    history.push("/patient/profile");
  }, []);

  return <Spinner />;
};

export default connect(null, { loadUser })(PatientAdminLogin);
